"use client";
import { getSiteInfo } from "@/sites";
import { Link } from "../Link";
import classNames from "classnames";

import "./styles.scss";

interface I_Logo {
  responsive?: boolean;
  href?: string;
  src: string;
  tabletSrc?: string;
  mobileSrc?: string;
  siteName: string;
  dataQa?: string;
  dataTestId?: string;
  className?: string;
  setSource?: (event: string) => void;
}

export const Logo = ({
  responsive = false,
  href,
  src,
  tabletSrc,
  mobileSrc,
  siteName,
  dataQa = "logo-data-qa",
  dataTestId = "logo-data-testid",
  className,
  setSource,
}: I_Logo) => {
  const classes = classNames("main-logo", {
    "main-logo--responsive": responsive,
    [className]: className,
  });

  return (
    <div
      className={classes}
      data-qa={dataQa}
      data-testid={dataTestId}>
      <WithLink
        href={href}
        siteName={siteName}
        setSource={setSource}>
        <img
          src={src}
          alt={`${siteName} logo`}
          className="desktop"
        />
        {responsive && (
          <img
            src={tabletSrc || src}
            alt={`${siteName} logo`}
            className="tablet"
          />
        )}
        {responsive && (
          <img
            src={mobileSrc || src}
            alt={`${siteName} logo`}
            className="mobile"
          />
        )}
      </WithLink>
    </div>
  );
};

const WithLink = ({ href, siteName, children, setSource }) => {
  if (!href) {
    return children;
  }

  return (
    <Link
      aria-label={`${siteName} logo`}
      href={href}
      onClick={() => setSource && setSource("")}>
      {children}
    </Link>
  );
};
