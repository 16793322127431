"use client";

import { useEffect, useState } from "react";
import { Wrapper } from "@/components/Wrapper";
import { TERMS_AND_CONDITIONS } from "@/internals/constants/variables/terms-and-conditions";
import { useWindowSize } from "@/hooks/useWindowsSize";
import { getSiteInfo } from "@/sites";

export const FooterCopyright = () => {
  const screenSize = useWindowSize();

  const { domainName } = getSiteInfo(process.env.NEXT_PUBLIC_DOMAIN);

  // prevent Hydration Error:
  const [windowSizeChange, setWindowSizeChange] = useState(false);

  useEffect(() => {
    setWindowSizeChange(true);
  }, []);

  return (
    <div className="footer__copyright">
      <Wrapper customClass="footer__wrapper-copyright">
        <div className="footer__text">
          QR Code is a registered trademark of DENSO WAVE INCORPORATED
        </div>
        <div className="footer__text">
          © {new Date().getFullYear()} {domainName} All rights reserved
        </div>
      </Wrapper>
    </div>
  );
};
