"use client";

import { useContext } from "react";
import classNames from "classnames";
import { FiMenu, FiX } from "react-icons/fi";

import { LayoutContext } from "@/contexts/LayoutContext";

import { HamburgerProps } from "./types";

import "./styles.scss";

export const Hamburger = ({ customClass, children }: HamburgerProps) => {
  const classnames = classNames(`hamburger`, {
    [`${customClass}`]: customClass,
  });

  const { isMenuOpened, setIsMenuOpened } = useContext(LayoutContext);

  const handleOnClick = () => {
    setIsMenuOpened(!isMenuOpened);
    const bodyElement = document.querySelector("body");
    const htmlElement = document.querySelector("html");
    if (isMenuOpened === false) {
      bodyElement.classList.add("overflowHidden");
      htmlElement.classList.add("overflowHidden");
    } else if (isMenuOpened === true) {
      bodyElement.classList.remove("overflowHidden");
      htmlElement.classList.remove("overflowHidden");
    }
  };

  return (
    <div
      data-testid={"hamburger"}
      className={`${classnames} ${isMenuOpened ? "hamburger--isOpen" : ""}`}>
      <nav className={`hamburger__links ${customClass}`}>
        <div className="hamburger__links-wrapper">{children}</div>
      </nav>
      <div
        onClick={() => handleOnClick()}
        className={`hamburger__button ${customClass}`}>
        {isMenuOpened ? <FiX title={"hamburger_icon"} /> : <FiMenu title={"hamburger_icon"} />}
      </div>
    </div>
  );
};
